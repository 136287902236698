<template>
  <div>
    <div class="box">
      <el-card
        :body-style="{
          padding: '0px',
          display: 'flex',
     
          
        }"
        v-for="(o, index) in moreAryCur"
        :key="index"
        class="card"
        shadow="hover"
      >
        <img :src="o.imgpath" class="image" @click="goTo(o)" />
        <div @click="goTo(o)" style="width: 60%; display: block">
          <span
            style="
              font-size: 14px;
              height: 40px;
              width: 100%;
              display: block;
              text-overflow: ellipsis;

              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
            "
            >{{ o.title }}</span
          >
          <div class="bottom clearfix">
            <time class="time"> 作者：{{ o.author ? o.author : "暂无" }}</time>
            <!-- <time class="time"> 出版社：{{ o.press }}</time> -->
            <!-- <time class="time"> 页数：{{ o.pagecount }}</time> -->

            <!-- <el-button type="text" class="button">操作按钮</el-button> -->
          </div>
        </div>
      </el-card>
    </div>
    <div style="width: 100%; text-align: center; margin-top: 20px">
      <el-pagination
        v-if="moreAryCur.length != 0"
        small
        layout="prev, pager, next"
        :total="moreCountCur"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage3"
        :page-size="10"
      >
      </el-pagination>
    </div>
  </div>
</template>
<style scoped>
.time {
  font-size: 13px;
  color: #999;
  margin-top: 8px;
  line-height: 17px;
  margin-bottom: 0;
  display: block;
  text-overflow: ellipsis;
  display: block;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.bottom {
  /* margin-top: 13px; */
  line-height: 12px;
  margin-top: 0;
}

.button {
  padding: 0;
  float: right;
}

.image {
  width: 60px;
  height: 90px;
  margin-right: 5px;
  /* overflow: hidden;
    display: block;
    height: 150px; */
}
.card {
  margin-bottom: 10px;
  cursor: pointer;
  /* padding-top: 5px;
    padding-left: 5px; */
  /* height: 100px; */
  padding: 5px;
  height: 90px;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}
.box {
  /* display: flex;

  justify-content:space-between;
  flex-wrap: wrap; */

  display: grid;

  grid-template-columns: 15% 15% 15% 15% 15% 15%;
  grid-gap: 24px;
}
.card {
  margin-bottom: 10px;

  text-align: left;
}
</style>

<script>
export default {
  data() {
    return {
      currentDate: new Date(),
      page: 1,
      currentPage3: 1,
      limit: 12,
      moreAryCur: [],
      moreCountCur: 0,
    };
  },
  props: {
    moreCount: Number,
    moreAry: Array,
  },
  watch: {
    moreAry(val) {
      console.log(val);
      this.moreAryCur = val;
      console.log(this.moreAryCur);
    },
    moreCount(val) {
      console.log(val);
      this.moreCountCur = val;
      console.log(this.moreCountCur);
    },
  },
  mounted() {
    console.log(this.moreAry);
    this.moreAryCur = this.moreAry;
    this.moreCountCur = this.moreCount;
  },
  methods: {
    goTo(obj) {
      console.log(0);
      window.localStorage.setItem("anjing_detail_new", JSON.stringify(obj));
      // if(window.localStorage.getItem('anjing_name_ebook') && window.localStorage.getItem('anjing_name_ebook')!=''){//已登录
      // this.$router.replace({ path: "./course", query: { id: obj.seqid } });
      window.location.href=`/#/course?id=${obj.seqid}`
      // }
      // else {
      //   this.$confirm('登录后可继续阅读, 是否登录?', '登录提示', {
      //     confirmButtonText: '确定',
      //     cancelButtonText: '取消',
      //     type: 'warning'
      //   }).then(() => {
      //     this.loginFun();
      //   }).catch(() => {

      //   });
      // }
      // this.$router.push({path:'./home_detail'})
    },
    loginFun() {
      debugger;
      let data = {
        op: "login",
      };
      let _this = this;
      this.$ajax
        .post(this.url + "/index.do", _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            window.location.href = response.data.data;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.page = val;
      this.$emit("changePage", val);
    },
  },
};
</script>