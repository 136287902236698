<template>
  <div>
    <heade></heade>

      <div class="box-width more common-height">
        <p
          style="
            text-align: left;
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 20px;
            overflow: auto;
            position: sticky;
    top: 84px;
    background: #fff;
    padding-top:10px;
    z-index: 100;
          "
        >
          <!-- <el-button type="success" size="mini" style="float:left;margin-right:15px;" @click="goBack">上一页</el-button> -->
          <el-breadcrumb separator="/" style="margin-bottom: 10px">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item
              ><a href="JavaScript:;" @click="goBack"
                >上一页</a
              ></el-breadcrumb-item
            >
            <!-- <el-breadcrumb-item>活动列表</el-breadcrumb-item>
       <el-breadcrumb-item>活动详情</el-breadcrumb-item> -->
          </el-breadcrumb>
          <span style="float: left; margin-right: 15px"> {{ moreBook }}</span>
        </p>

        <el-tabs
          :tab-position="tabPosition"
          v-model="activeName"
          @tab-click="handleClick"
        >
          <el-tab-pane label="全部" name="first">
            <h-card
              :moreAry="moreAry"
              :total="moreCount"
              @changePage="changePage"
            ></h-card>
          </el-tab-pane>
          <el-tab-pane
            :label="item.title"
            :name="index + 1 + ''"
            v-for="(item, index) in typeList"
            :key="index"
          >
            <h-card
              :moreAry="moreAry"
              :total="moreCount"
              @changePage="changePage"
            ></h-card>
          </el-tab-pane>
          <!-- <el-tab-pane label="小说" name="third"> <h-card></h-card> </el-tab-pane>
       <el-tab-pane label="历史文化" name="fourth">
         <h-card></h-card>
       </el-tab-pane>

       <el-tab-pane label="社会纪实" name="sj">
         <h-card></h-card>
       </el-tab-pane>
       <el-tab-pane label="科学新知" name="kx">
         <h-card></h-card>
       </el-tab-pane>
       <el-tab-pane label="艺术设计" name="ys">
         <h-card></h-card>
       </el-tab-pane>
       <el-tab-pane label="商业经管" name="sy">
         <h-card></h-card>
       </el-tab-pane> -->
        </el-tabs>
      </div>
      <footerC></footerC>

  </div>
</template>
<style scoped>
.dl {
  display: flex;
  justify-content: flex-start;
  text-align: left;
}
.dl dd {
  margin-right: 15px;
}
.dl dt p {
  margin-bottom: 15px;
}
.common-height {
  /* height: calc(100vh - 240px); */
}
</style>
<style >
.more .el-tabs__content {
  /* height: calc(100vh - 240px);
   */
   /* height:calc(100vh - 274px);*/
    min-height: calc(100vh - 438px); 
  overflow: auto;
}
</style>
<style>
.el-tabs--left, .el-tabs--right{
  overflow: inherit;
}
.el-tabs--left .el-tabs__header.is-left{
  position: sticky;
    top: 145px;
}
</style>
<script>
import heade from "@/components/heade.vue";
import HCard from "../components/hCard.vue";
import footerC from "@/components/footerCommon.vue";
export default {
  components: { heade, HCard, footerC },
  //   props: {
  //     column:String
  //   },
  data() {
    return {
      tabPosition: "left",
      activeName: "first",
      type: "",
      moreAry: [],
      typeList: [],
      moreBook: "",
      moreCount: 0,
      limit: 15,
      page: 1,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    this.moreBook = window.localStorage.getItem("anjing_more_type");
    this.init();
  },
  methods: {
    changePage(page) {
      this.page = page;
      this.initColumn(this.moreBook, 0);
    },
    init() {
      let data = {
        op: "typelist",
      };
      let _this = this;
      this.$ajax
        .post(this.url + "/index.do", _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.typeList = response.data.data;
            _this.initColumn(_this.moreBook, 0);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    handleClick(val) {
      if (val.label == "全部") {
        val.label = "";
      }
      this.type = val.label;
      this.initColumn(this.moreBook, 0);
    },
    goBack() {
      this.$router.push({ path: "./" });
    },
    initColumn(column, istop) {
      let data = {
        op: "list",
        page: this.page,
        column: column,
        type: this.type,
        limit: this.limit,
        istop: istop,
      };
      let _this = this;
      this.$ajax
        .post(this.url + "/index.do", _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.moreAry = response.data.data;
            console.log(_this.moreAry);
            _this.moreCount = response.data.recordcount;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>