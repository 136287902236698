<template>
  <div>
    <heade msg="Welcome to Your Vue.js App"></heade>
    <div class="common-height">
      <div class="box-width">
        <!-- <el-button
        type="success"
        plain
        size="mini"
        style="float: left; margin-right: 15px"
        @click="backIndex"
        >上一页</el-button
      >
      <el-button
        type="success"
        size="mini"
        style="float: left; margin-right: 15px"
        
        >上一页</el-button
      > -->
        <el-breadcrumb separator="/" style="margin-bottom: 10px">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item
            ><a href="JavaScript:;" @click="goBack"
              >上一页</a
            ></el-breadcrumb-item
          >
          <!-- <el-breadcrumb-item>活动列表</el-breadcrumb-item>
        <el-breadcrumb-item>活动详情</el-breadcrumb-item> -->
        </el-breadcrumb>

        <p
          style="
            text-align: left;
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 20px;
            width: 240px;
          "
        >
          {{ info.title }}
        </p>
        <dl class="dl">
          <dd><img :src="info.imgpath" width="240px" /></dd>
          <dt>
            <p>作者：{{ info.author?info.author:'暂无' }}</p>
            <p>出版社：{{ info.press }}</p>
            <p>出品方：{{ info.producer }}</p>
            <!-- <p>副标题： {{info.title}}</p> -->
            <p>翻译： {{ info.translator }}</p>
            <!-- <p>原作名： Behind the Screen:Content Moderation in the Shadows of Social Media</p> -->
            <p>出版年： {{ info.time }}</p>
            <p>页数： {{ info.pagecount }}</p>
            <el-button
              type="primary"
              size="mini"
              @click="redFun(info.seqid, info.title)"
              >去阅读</el-button
            >
          </dt>
        </dl>
      </div>
      <el-divider></el-divider>

      <div class="box-width">
        <p
          style="
            text-align: left;
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 20px;
          "
        >
          内容简介
        </p>
        <!-- <div style="text-align: left" v-html="markdownToHtml"></div> -->
        <VueMarkdown :source="info.content"  class="articalContent markdown-body "></VueMarkdown>
      </div>
    </div>
    <footerC></footerC>
  </div>
</template>
<style scoped>
.dl {
  display: flex;
  justify-content: flex-start;
  text-align: left;
}
.dl dd {
  margin-right: 15px;
}
.dl dt p {
  margin-bottom: 15px;
}
</style>
<script>
import heade from "@/components/heade.vue";
import VueMarkdown from 'vue-markdown'
// import {marked} from "marked";
import footerC from "@/components/footerCommon.vue";
export default {
  components: { heade, footerC,VueMarkdown },
  data() {
    return {
      info: {},
      // marked
    };
  },
  mounted() {
    if (window.localStorage.getItem("anjing_detail_new")) {
      this.info = JSON.parse(window.localStorage.getItem("anjing_detail_new"));
      // this.info.content = "### 简介\r\naaaaaa \r\n\r\n###目录\r\n1. 很好\r\n2. 版本"

    } else {
      this.$router.push({ path: "./" });
    }
    console.log();
  },

  // computed: {
  //   markdownToHtml() {
    
  //     return marked((JSON.parse(window.localStorage.getItem("anjing_detail_new"))).content);
  //   },
  // },
  methods: {
    backIndex() {
      this.$router.push({ path: "./" });
    },
    goBack() {
      this.$router.go(-1);
    },
    loginFun() {
      debugger
      let data = {
        op: "login",
      };
      let _this = this;
      this.$ajax
        .post(this.url + "/index.do", _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            window.location.href = response.data.data;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    redFun(id, name) {
      //         const PDFJS = require("pdfjs-dist");
      // console.log("成功：",PDFJS )
      // if(window.localStorage.getItem('anjing_name_ebook') && window.localStorage.getItem('anjing_name_ebook')!=''){//已登录
        // this.$router.replace({ path: "./course", query: { id: id} });
        window.location.href=`/#/course?id=${obj.seqid}`
      // }
      // else {
      //   this.$confirm('登录后可继续阅读, 是否登录?', '登录提示', {
      //     confirmButtonText: '确定',
      //     cancelButtonText: '取消',
      //     type: 'warning'
      //   }).then(() => {
      //     this.loginFun();
      //   }).catch(() => {
             
      //   });
      // }
   
      // this.$router.push({path:'./pdf_list',query:{id:id,name:name}})
    },
  },
};
</script>