<template>
  <div>
    <heade @searchFun="searchFun"></heade>
    <div class="box-width common-height">
      <el-breadcrumb separator="/" style="margin-bottom: 10px">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item
          ><a href="JavaScript:;" @click="goBack">上一页</a></el-breadcrumb-item
        >
        <!-- <el-breadcrumb-item>活动列表</el-breadcrumb-item>
        <el-breadcrumb-item>活动详情</el-breadcrumb-item> -->
      </el-breadcrumb>

      <p
        style="
          text-align: left;
          font-size: 20px;
          font-weight: bold;
          margin-bottom: 20px;
        "
      >
        搜索 【 {{ searchVal }}】
      </p>
      <h-card
        :moreAry="moreAry"
        :total="moreCount"
        @changePage="changePage"
      ></h-card>
    </div>

    <footerC></footerC>
  </div>
</template>
<style scoped>
.dl {
  display: flex;
  justify-content: flex-start;
  text-align: left;
}
.dl dd {
  margin-right: 15px;
}
.dl dt p {
  margin-bottom: 15px;
}
</style>
<script>
import heade from "@/components/heade.vue";
import HCard from "../components/hCard.vue";
import footerC from "@/components/footerCommon.vue";
export default {
  components: { heade, HCard, footerC },
  mounted() {
    window.scrollTo(0, 0);
    this.searchVal = window.localStorage.getItem("anjing_input3_name");
    this.init();
  },
  data() {
    return {
      moreAry: [],
      moreCount: 0,
      page: 0,
      limit: 15,
      searchVal: "",
    };
  },
  methods: {
    changePage(page) {
      this.page = page;
      this.init();
    },
    //window.localStorage.getItem('anjing_input3_name')
    searchFun(val) {
      this.init();
    },
    init() {
      let data = {
        op: "search",
        page: this.page,
        limit: this.limit,
        name: window.localStorage.getItem("anjing_input3_name"),
      };
      let _this = this;
      this.$ajax
        .post(this.url + "/index.do", _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.moreAry = response.data.data;
            _this.moreCount = response.data.recordcount;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>