<template>
  <div class="home">
    <div class="typepc">
    <!--- :style="{ color: color, background: bg }"-->
    <div class="heade" :style="{ color: color, background: bg }">
      <div class="heade-box">
        <img
          :src="iconpath"
          width="40px"
          style="float: left; margin-top: 25px; margin-right: 20px"
        />
        {{ name }} -- {{ bigTitle }}
        <el-button
          plain
          size="mini"
          :style="{ color: color, background: bg, float: 'right' }"
          class="headeBg"
          @click="backIndex"
        >
          <i class="el-icon-s-home" style="font-size: 16px"></i>
        </el-button>
      </div>
    </div>
    <div class="common-height" style="width: 90%; margin: 0 auto">
      <!-- <el-carousel :interval="4000" type="card" height="240px" v-if="bannerIs">
      <el-carousel-item v-for="item in imgAry" :key="item">
   
        <img :src="item" width="100%" />
      </el-carousel-ite30
    </el-carousel> -->

      <div>
        <div style="display: flex;justify-content:space-between;">
        <div style="width:100%;flex:3">
          <el-carousel
            :interval="5000"
            arrow="always"
            v-if="bannerIs"
            height="400px"
          
          >
            <el-carousel-item v-for="item in imgAry" :key="item">
              <!-- <img :src="item" width="100%" style="object-fit: cover" /> -->
              <div
                :style="{
                  width: '100%',
                  height: '400px',
                  background: `url(${item}) no-repeat center top`,
                  backgroundSize: 'cover',
                  position: 'relative'
                }
                "
              ></div>
            </el-carousel-item>
          </el-carousel>
        </div>

        <div
          style="
            width: 100%;
            margin: 0 auto;
            margin-bottom: 11px;
           margin-left:5px;
            flex:1;

          "
        >
          <el-card shadow="always" style=" height:400px;">
            <p><img  src="https://img0.baidu.com/it/u=85622950,1957812922&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=281" height="100px" width="100%"/></p>
            <VueMarkdown
              :source="info"
              class="articalContent markdown-body"
              style="text-align: left; font-size: 14px;overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 11;
    -webkit-box-orient: vertical;"
            ></VueMarkdown>
          </el-card>
        </div>
      </div>
        <div
          style="
            padding: 10px 10px;
            margin-top: 20px;
            text-align: left;
            background: #f3f3f3;
            margin-bottom:20px;
            border-radius: 10px;
          "
        >
          <el-input
            placeholder="请输入内容"
            v-model="input3"
            style="width: 30%; margin-bottom: 15px"
            size="mini"
            class="input-with-select"
            @change="listFun"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="listFun"
            ></el-button>
          </el-input>
          <cardList
            v-if="moreAry.length != 0"
            :moreAry="moreAry"
            :moreCount="moreCount"
            @changePage="changePage"
          ></cardList>
          <div v-else>
            <el-result icon="warning" title="暂无数据" subTitle=""> </el-result>
          </div>
        </div>
      </div>
    </div>
  </div>
    <div class="typephone">
      <div class="heade heade-phone" :style="{ color: color, background: bg }">
      <div class="heade-box">
        <img
          :src="iconpath"
          width="40px"
          style="float: left; margin-top: 25px; margin-right: 20px"
        />
        {{ name }} -- {{ bigTitle }}
        <el-button
          plain
          size="mini"
          :style="{ color: color, background: bg, float: 'right' }"
          class="headeBg"
          @click="backIndex"
        >
          <i class="el-icon-s-home" style="font-size: 16px"></i>
        </el-button>
      </div>
    </div>
    <div class="common-height" style="width: 99%; margin: 0 auto">
      <!-- <el-carousel :interval="4000" type="card" height="240px" v-if="bannerIs">
      <el-carousel-item v-for="item in imgAry" :key="item">
   
        <img :src="item" width="100%" />
      </el-carousel-ite30
    </el-carousel> -->

      <div>
        <div style="display: flex;justify-content:space-between;">
        <div style="width:100%;flex:3">
          <el-carousel
            :interval="5000"
            arrow="always"
            v-if="bannerIs"
            height="140px"
          
          >
            <el-carousel-item v-for="item in imgAry" :key="item">
              <!-- <img :src="item" width="100%" style="object-fit: cover" /> -->
              <div
                :style="{
                  width: '100%',
                  height: '400px',
                  background: `url(${item}) no-repeat center top`,
                  backgroundSize: '100%',
                  position: 'relative'
                }
                "
              ></div>
            </el-carousel-item>
          </el-carousel>
        </div>

        <!-- <div
          style="
            width: 100%;
            margin: 0 auto;
            margin-bottom: 11px;
           margin-left:5px;
            flex:1;

          "
        >
          <el-card shadow="always" style=" height:400px;">
            <p><img  src="https://img0.baidu.com/it/u=85622950,1957812922&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=281" height="100px" width="100%"/></p>
            <VueMarkdown
              :source="info"
              class="articalContent markdown-body"
              style="text-align: left; font-size: 14px;overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 11;
    -webkit-box-orient: vertical;"
            ></VueMarkdown>
          </el-card>
        </div> -->
      </div>
        <div
          style="
            padding: 10px 10px;
            margin-top: 20px;
            text-align: left;
            background: #f3f3f3;
            margin-bottom:20px;
            border-radius: 10px;
          "
        >
          <el-input
            placeholder="请输入内容"
            v-model="input3"
            style="width: 90%; margin-bottom: 15px"
            size="mini"
            class="input-with-select"
            @change="listFun"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="listFun"
            ></el-button>
          </el-input>
          <cardListPhone
            v-if="moreAry.length != 0"
            :moreAry="moreAry"
            :moreCount="moreCount"
            @changePage="changePage"
          ></cardListPhone>
          <div v-else>
            <el-result icon="warning" title="暂无数据" subTitle=""> </el-result>
          </div>
        </div>
      </div>
    </div>
    </div>
    <footerC :qy="name"></footerC>
  </div>
</template>
  
  <script>
// @ is an alias to /src
import cardList from "@/components/cardList_temp.vue";

import cardListPhone from "@/components/cardList_temp_phone.vue";
import footerC from "@/components/footerCommon.vue";
import VueMarkdown from "vue-markdown";
export default {
  name: "Home",
  components: { cardList, footerC, VueMarkdown,cardListPhone },
  data() {
    return {
      name: "",
      iconpath: "",
      info: "",
      bannerIs: true,
      color: "",
      bg: "",
      bigTitle: "",
      input3: "",
      page: 0,
      id: "",
      imgAry: [],
      moreAry: [],
      moreCount: 8,
      page: 1,
      limit: 10,
      typeUrl:'pc'
    };
  },
  mounted() {
    console.log(this.$route.query);
    this.id = this.$route.query.id;
    this.lbtFun();
    this.listFun();
    let windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
console.log(windowWidth)
    if(windowWidth<880){//移动端
      this.typeUrl= 'phone';
      this.chnageWidth='100%';
    }
    else {
      this.typeUrl= 'pc';
      this.chnageWidth='1340px';
    }
  },
  methods: {
    backIndex() {
      this.$router.push({ path: "./" });
    },
    changePage(page) {
      this.page = page;
      this.listFun();
    },
    lbtFun() {
      let data = {
        op: "detail",
        _id: this.id,
      };
      let _this = this;
      this.$ajax
        .post(this.url + "/company.do", _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.imgAry = response.data.data.banner;
            _this.bigTitle = response.data.data.title;
            _this.bg = response.data.data.bg;
            _this.color = response.data.data.color;
            _this.bannerIs = response.data.data.bannerIs;
            _this.info = response.data.data.intro;
            _this.iconpath = response.data.data.iconpath;
            _this.name = response.data.data.name;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    listFun() {
      let data = {
        op: "search",
        _id: this.id,
        page: this.page,
        limit: this.limit,
        title: this.input3,
      };
      let _this = this;
      this.$ajax
        .post(this.url + "/company.do", _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.moreAry = response.data.data;
            _this.moreCount = response.data.recordcount;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>
  <style >
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>
<style scoped>
.home {
  height: 100vh;
  background: #fbfbfb;
}

.heade {
  text-align: left;
  line-height: 89px;
  font-weight: bold;
  font-size: 18px;
  /* padding: 0 100px; */
  height: 89px;
  position: sticky;
  margin-bottom: 11px;
  top: 0;
  z-index: 99;
  box-shadow: 1px 2px 3px 2px #ccc;
  width: 100%;

  /* margin-bottom: 20px; */
  box-sizing: border-box;
  background: #ffb70b;

  width: 100%;
  min-width: 1220px;

  /* background: rgba(0, 0, 0, 0.5); */
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(20px);
 
  z-index: 999;

}
.heade-phone{
  min-width:100%;
}

.headeBg {
  /* margin-right: 15px; */
  margin-top: 28px;
  /* float: left; */
  background: #ffb70b;
}
.markdown-body h3{
  margin: 10px 0;
}
.heade-box {
  max-width: 90%;
  margin: 0 auto;
}
.typepc{
  display:block
}
.typephone{
  display:none
}
@media (max-width: 880px) {
  .typepc{
  display:none
}
.typephone{
  display:block
}
}
</style>
  