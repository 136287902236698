<template>
  <div>
    <div class="box">
      <el-card
        :body-style="{
          padding: '0px',
          display: 'flex',
          justifyContent: 'space-between',
        }"
        v-for="(o, index) in moreAryCur"
        :key="index"
        class="card"
        shadow="hover"
      >
        <img
          :src="o.imgpath"
          class="image"
          @click="goTo(o)"
        />
        <div style="padding: 14px; width: 90%" @click="goTo(o)">
          <span>{{o.title}}</span>
          <div class="bottom clearfix">
            <time class="time"> 作者：{{ o.author?o.author:'暂无' }}</time>
            <p class="time">{{o.intro}}</p>
            <!-- <el-button type="text" class="button">操作按钮</el-button> -->
          </div>
        </div>
      </el-card>

      <el-pagination
      v-if="moreAryCur.length!=0"
        small
        layout="prev, pager, next"
        :total="totalCur"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage3"
        :page-size="15"

      >
      </el-pagination>
      <div style="width: 100%; text-align: right">
        <!-- <el-pagination
    small
    layout="prev, pager, next"
    :total="50">
  </el-pagination> -->
      </div>
    </div>
  </div>
</template>
  <style scoped>
.time {
  font-size: 13px;
  color: #999;
  padding: 5px 0;
  line-height: 17px;
}

.bottom {
  margin-top: 13px;
  line-height: 12px;
}

.card {
  margin-bottom: 10px;
  cursor: pointer;
}
.button {
  padding: 0;
  float: right;
}

.image {
  width: 80px;
  display: block;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}
.box {
  /* display: flex;
  
    justify-content:space-between;
    flex-wrap: wrap; */

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  grid-gap: 4px;
}
.card {
  margin-bottom: 10px;

  text-align: left;
}
</style>
  
  <script>
export default {
  data() {
    return {
      currentDate: new Date(),
      page: 1,
      limit: 12,
      currentPage3:1,
      moreAryCur:[],
      totalCur:0
   
    };
  },
  props:{
    moreAry:Array,
    total:Number
  },
  mounted(){



  },
  watch:{
    moreAry(val){
        console.log(val)
        this.moreAryCur= val;
        console.log(this.moreAryCur)
        
    },
    total(val){
        console.log(val)
        this.totalCur= val;
        console.log(this.totalCur)
        
    }
  },
  methods: {
    goTo(obj) {
      console.log(0);
      window.localStorage.setItem('anjing_detail_new',JSON.stringify(obj) )
      // if(window.localStorage.getItem('anjing_name_ebook') && window.localStorage.getItem('anjing_name_ebook')!=''){//已登录
        this.$router.replace({ path: "./course", query: { id: obj.seqid} });
      // }
      // else {
        // this.$alert('请点击右上角登录按钮，登录后可继续阅读。', {
        //   confirmButtonText: '确定',
        //   callback: action => {
        //     // this.$message({
        //     //   type: 'info',
         
        //     // });
        //   }
        // })
      //   this.$confirm('登录后可继续阅读, 是否登录?', '登录提示', {
      //     confirmButtonText: '确定',
      //     cancelButtonText: '取消',
      //     type: 'warning'
      //   }).then(() => {
      //     this.loginFun();
      //   }).catch(() => {
             
      //   });
      // }
      // this.$router.push({ path: "./home_detail" });
    },

 
    loginFun() {
      debugger
      let data = {
        op: "login",
      };
      let _this = this;
      this.$ajax
        .post(this.url + "/index.do", _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            window.location.href = response.data.data;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    

    handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.page = val;
        this.$emit('changePage',val)

      }
  },
};
</script>